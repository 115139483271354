import DocAdmin from '@/store/data'

export function processSearchResponse(response, filters) {
  const results = response.data
  
  // Transform facets to match the format expected by our components
  if (results.facets) {
    const processedFacets = {
      document_types: results.facets.document_types || [],
      document_item_types: results.facets.document_item_types || [],
      documents: results.facets.documents || [],
      languages: results.facets.languages || []
    }

    // Add selected state to each facet
    Object.entries(processedFacets).forEach(([facetName, buckets]) => {
      const paramMap = {
        'document_types': 'doc_type',
        'document_item_types': 'doc_item_type',
        'documents': 'document',
        'languages': 'language'
      }

      const param = paramMap[facetName]
      const selectedValues = filters
        .filter(([key]) => key === param)
        .map(([_, value]) => value)

      buckets.forEach(bucket => {
        bucket.selected = selectedValues.includes(bucket.key)
      })
    })

    results.facets = processedFacets
  }

  return results
}

export default {
  namespaced: true,
  state: () => ({
    documentSearchResults: undefined,
    documentSearchQueryParams: undefined
  }),
  actions: {
    /**
     * Search for documents.
     *
     * @param {Object} commit - Vuex commit from context object
     * @param {Object} payload - Search parameters
     * @param {string} payload.query - user provided query
     * @param {number} payload.page - page number within results
     * @param {number} payload.pageSize - number of items per page
     * @param {string[][]} payload.filters - filters to add to query
     * @param {string} payload.type - search type (default, wildcard, or regex)
     * @return {Promise}
     */
    findDocuments({ commit }, payload) {
      const filters = payload.filters || []
      
      // Build base params
      const params = [
        ...filters,
        ['page', payload.page || 1],
        ['page_size', payload.pageSize || 20]
      ]

      // Add search query with type if provided
      if (payload.query) {
        if (payload.type === 'wildcard') {
          params.push(['q', payload.query], ['type', 'wildcard'])
        } else if (payload.type === 'regex') {
          params.push(['q', payload.query], ['type', 'regex'])
        } else {
          params.push(['q', payload.query])
        }
      }

      // Remove null/undefined values and convert to URLSearchParams
      const cleanParams = new URLSearchParams(
        params.filter(([_, value]) => value != null)
      )

      // Convert to object for API call
      const apiParams = Object.fromEntries(cleanParams.entries())
      
      return DocAdmin.documentSearch.list({
        params: apiParams
      })
      .then((response) => {
        const results = processSearchResponse(response, filters)
        commit('SET_DOCUMENT_SEARCH_RESULTS', { 
          results, 
          qs: cleanParams 
        })
      })
      .catch((error) => {
        console.error('search.findDocuments.error', error)
        throw error // Allow component to handle error
      })
    }
  },
  mutations: {
    /**
     * Store /api/search/documents/ results.
     *
     * @param {Object} state - Vuex state tree.
     * @param {Object} payload - mutation payload.
     * @param {Object} payload.results - API response
     * @param {Object} payload.qs - Query string params
     */
    SET_DOCUMENT_SEARCH_RESULTS(state, { results, qs }) {
      state.documentSearchResults = results
      state.documentSearchQueryParams = qs
    }
  }
}